import React, { useState, useEffect } from "react";
import * as styles from "../styles/shape.css";

const smallD = [700, 20, 28.4, 97.74, 13.51, 75.41, 18.9, 45.32, 40.61, 1.55];
const bigD = [500, 80, 100.4, 200.74, 13.51, 75.41, 70.9, 70.32, 20.61, 10.55];
const incremenets = new Array(smallD.length);

function updateD(d, increments, parity) {
  if (increments[0] == null) {
    return d;
  }
  for (let i = 0; i < d.length; i++) {
    d[i] = d[i] + increments[i] * parity;
  }
  return d;
}

function Shape() {
  const [tick, setTick] = useState(0);
  const [tickParity, setTickParity] = useState(1);
  const [d, setD] = useState(smallD);

  useEffect(() => {
    for (let i = 0; i < smallD.length; i++) {
      incremenets[i] = (bigD[i] - smallD[i]) / 255;
    }
  }, []);

  useEffect(() => {
    const ticker = window.setInterval((_tick) => {
      if (tickParity === 1) {
        setTick((prevTick) => prevTick + 1);
        if (tick >= 255) {
          setTick(() => 254);
          setTickParity(() => -1);
        }
      } else {
        setTick((prevTick) => prevTick - 1);
        if (tick <= 0) {
          setTick(() => 1);
          setTickParity(() => 1);
        }
      }
      setD((_d) => updateD(_d, incremenets, tickParity));
    }, 10);
    return () => {
      window.clearInterval(ticker);
    };
  }, [tick, tickParity, d]);

  return (
    <svg className="svg" styles={{ styles }} width="100%" height="100%">
      <defs>
        <linearGradient
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="mx-gradient-99ffff-1-ff00ff-1-s-0"
        >
          <stop
            offset="0%"
            style={{ stopColor: `rgba(249, 200, ${tick}, 1)` }}
          />
          <stop
            offset="100%"
            style={{ stopColor: `rgba(249, ${tick}, 0, 1)` }}
          />
        </linearGradient>
      </defs>
      <g>
        <path
          //   d={`M ${d[0].toString()} ${d[1].toString()} L ${d[2].toString()} ${d[3].toString()} C ${d[4].toString()} ${d[5].toString()} ${d[6].toString()} ${d[7].toString()} ${d[8].toString()} ${d[9].toString()} Z`}
          d={`M ${d[0]} ${d[1]} L ${d[2]} ${d[3]} C ${d[4]} ${d[5]} ${d[6]} ${d[7]} ${d[8]} ${d[9]} Z`}
          fill="url(#mx-gradient-99ffff-1-ff00ff-1-s-0)"
          stroke="none"
          transform="rotate(-145,70,70)"
          pointerEvents="all"
        />
      </g>
    </svg>
  );
}

export default Shape;
