import React from "react";
import * as styles from "../styles/circle.css";

function Circle() {
  return (
    <svg className="svg" styles={{ styles }}>
      <circle
        className="svg-circle"
        stroke="rgba(100,255,255,1)"
        r="10"
        strokeWidth="100"
      />
    </svg>
  );
}

export default Circle;
