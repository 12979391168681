import React, { useState, useEffect } from "react";
import * as styles from "../styles/bar.css";

function Bar() {
  const [tick, setTick] = useState(0);
  const [tickParity, setTickParity] = useState(1);

  useEffect(() => {
    const ticker = window.setInterval((_tick) => {
      if (tickParity === 1) {
        setTick((prevTick) => prevTick + 1);
        if (tick >= 255) {
          setTick(() => 254);
          setTickParity(() => -1);
        }
      } else {
        setTick((prevTick) => prevTick - 1);
        if (tick <= 0) {
          setTick(() => 1);
          setTickParity(() => 1);
        }
      }
    }, 10);
    return () => {
      window.clearInterval(ticker);
    };
  }, [tick, tickParity]);

  return (
    <svg
      className="bar"
      styles={styles}
      version="1.1"
      width="351px"
      height="61px"
      viewBox="-0.5 -0.5 351 61"
    >
      <defs>
        <linearGradient x1="100%" y1="0%" x2="0%" y2="0%" id="mx-gradient">
          <stop
            offset="0%"
            style={{ stopColor: `rgba(249, 200, ${tick}, 1)` }}
          />
          <stop
            offset="100%"
            style={{ stopColor: `rgba(249, ${tick}, 0, 1)` }}
          />
        </linearGradient>
      </defs>
      <g>
        <rect
          x="0"
          y="0"
          width="350"
          height="60"
          rx="3.6"
          ry="3.6"
          fill="url(#mx-gradient)"
          pointerEvents="all"
        />
      </g>
    </svg>
  );
}

export default Bar;
